import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Input, Button, Select } from '@chakra-ui/react';
import { Activity, Challenge, User } from './definitions';
import { Controller, useForm } from 'react-hook-form';
import Layout from './Layout';
import moment from 'moment';
import { DeleteIcon } from '@chakra-ui/icons';
import Loading from './Loading';

const SECRET_KEY = 'trainers-secret';

const Home = () => {

  const [auth, setAuth] = useState<boolean| undefined>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [users, setUsers] = useState<User[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [challenge, setChallenge] = useState<Challenge>();

  const checkAuth = useCallback(() => {
    if (localStorage.getItem(SECRET_KEY) !== process.env.REACT_APP_TRAINERS_SECRET) {
      // eslint-disable-next-line no-restricted-globals
      const password = prompt("Ange lösenord för Reimes Trainers");
      if (password === null) {
        return;
      } else if (password.toLowerCase() === process.env.REACT_APP_TRAINERS_SECRET) {
        localStorage.setItem(SECRET_KEY, password.toLowerCase());
        setAuth(true);
      } else {
        setAuth(false);
        checkAuth();
      }
    } else if (localStorage.getItem(SECRET_KEY) === process.env.REACT_APP_TRAINERS_SECRET) {
      setAuth(true);
    }
  }, [])

  const fetchUsers = async () => {
    const { data } = await fetch(`${process.env.REACT_APP_API_URL}/users`).then((res) => res.json());
    setUsers(data);
  }

  const fetchActivities = async () => {
    const { data } = await fetch(`${process.env.REACT_APP_API_URL}/activities`).then((res) => res.json());
    setActivities(data);
  }

  const fetchChallenge = async () => {
    const { data } = await fetch(`${process.env.REACT_APP_API_URL}/challenges/1`).then((res) => res.json());
    setChallenge(data);
  }

  useEffect(() => {
    fetchUsers();
    fetchActivities();
    fetchChallenge();
    checkAuth();
  }, [checkAuth])

  const dateOptions = Array(8).fill(0).map((_, index) => {
    const label = index === 0 ? 'Idag' : (index === 1 ? 'Igår' : moment().subtract(index, 'days').fromNow()); 
    const value = moment().subtract(index, 'days').toISOString();
    return { label, value };
  })
  
  const { register, reset, control, handleSubmit } = useForm();
  
  const calculateTotalScore = (data: Activity[]) => {
    return Math.floor(data.reduce((acc, activity) => activity.duration + acc, 0));
  }

  const deleteActivity = async (activityId: number) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Är du säker på att du vill ta bort aktiviteten?");
    
    if (confirmed) {
      const { success } = await fetch(`${process.env.REACT_APP_API_URL}/activities/${activityId}`, {
        method: "DELETE"
      }).then(res => res.json());

      if (success) {
        // Remove deleted one locally
        setActivities(prev => ([
          ...prev.filter((activity: Activity) => activityId !== activity.id)
        ]));
      }
    }
  }

  const submitActivity = async (formData: any) => {
    setErrorMessage(undefined);
    setMessage(undefined);
    formData.user_id = parseInt(formData.user_id);
    const { success, data } = await fetch(`${process.env.REACT_APP_API_URL}/activities`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    }).then(res => res.json());

    if (success) {
      setActivities(prev => [data, ...prev]);
      setMessage("Aktivitet sparad!");
      window.scrollTo(0, 0);
      reset();
    } else {
      setErrorMessage("Något gick fel. Försök igen.");
    }
  }

  if (auth === false) {
    return (
      <Layout>
        <Loading text="Ange lösenord" />;
      </Layout>
    )
  }

  if (!challenge || !users || !activities) {
    return (
      <Layout>
        <Loading text="Laddar data" />;
      </Layout>
    )
  }

  return (
    <Layout>
      <Flex flexDir="column" alignSelf="center" mb="16px" w="100%" padding="24px"  borderRadius="8px" background="white" boxShadow="0 0 16px 0 rgba(0, 0, 0, 0.05)">
        <Flex flexDir={{ base: "column", md: "row" }}>
          <Box>
            <Heading mb="8px" fontSize={{ base: "18px", md: "24px" }}>{challenge.price}</Heading>
            <Heading mb={{ base: "8px", md: "16px" }} fontSize={{base: "16px", md: "18px" }} fontWeight="normal" color="#707788">Återstående tid: {moment(challenge.ends).diff(moment(), 'days')} dagar</Heading>
          </Box>
          <Box alignSelf={{ base: "flex-start", md: "center" }} ml={{ base: 0, md: "auto" }}>
            <Text fontWeight="bold" color="#003560">{calculateTotalScore(activities)} av {challenge.goal} poäng</Text>
          </Box>
        </Flex>

        <Progress score={calculateTotalScore(activities)} goal={challenge.goal} />
      </Flex>
      <Flex flexDir={{ base: "column", md: "row" }}>
        <Flex flexDir="column" flex="1" padding="16px" m={{ base: "8px 0", md: "0 8px" }} borderRadius="8px" background="white" boxShadow="0 0 16px 0 rgba(0, 0, 0, 0.05)" order={{ base: 2, md: 1 }}>
          <Heading fontSize={{ base: "20px", md: "24px" }} mb="12px">Senaste aktiviteterna</Heading>
          {activities.map((activity, index) => index < 6 && (
            <Flex key={index} alignItems="center" mb="16px">
              <Flex alignItems="center" justifyContent="center" borderRadius="50%" w="42px" h="42px" flex="0 0 42px" background="#005192" color="white">
                <Text fontWeight="bold" fontSize="16px">{activity.duration}p</Text>
              </Flex>
              <Box ml="12px" flex="1">
                <Text>{activity.first_name} tränade {activity.title} i {activity.duration} minuter</Text>
                <Text fontStyle="italic" lineHeight="20px" color="gray" fontSize="14px">{moment(activity.day).format("YYYY-MM-DD")}</Text>
              </Box>
              <DeleteIcon color="#003158" cursor="pointer" _hover={{ color: "black" }} w="20px" h="20px" flex="0 0 20px" onClick={() => deleteActivity(activity.id)}/>
            </Flex>
          ))}
        </Flex>
        <Flex flexDir="column" flex="1" padding="16px" m={{ base: "8px 0", md: "0 8px" }} borderRadius="8px" background="white" boxShadow="0 0 16px 0 rgba(0, 0, 0, 0.05)" order={{ base: 1, md: 2 }}>
          <Heading fontSize={{ base: "20px", md: "24px" }} mb="12px">Registrera träningspass</Heading>
          {message && (
            <Text mb="12px" color="green">{message}</Text>
          )}
          {errorMessage && (
            <Text mb="12px" color="red">{errorMessage}</Text>
          )}
          <form onSubmit={handleSubmit(submitActivity)}>
            <Text as="label">Namn</Text>
            <Controller
              name="user_id"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select
                    {...field}
                    mb="16px"
                    placeholder="Välj deltagare"
                >
                  {users.map(user => (
                    <option key={user.id} value={user.id}>{user.first_name}</option>
                ))}
                </Select>
              )}
            />

            <Text as="label">Datum</Text>
            <Controller
              name="day"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select
                    {...field}
                    mb="16px"
                    placeholder="Välj dag"
                >
                  {dateOptions.map((date, index) => (
                    <option key={index} value={date.value}>{date.label}</option>
                ))}
                </Select>
              )}
            />
            <Text as="label">Träningsform</Text>
            <Input {...register('title', { required: true })} mb="8px" />
            <Text as="label">Antal minuter</Text>
            <Input type="number" {...register('duration', { required: true, valueAsNumber: true })} mb="16px" />
            <Button type="submit" w="100%" background="#005192" color="white" _disabled={{ background: "gray", color: "black" }}>Spara</Button>
          </form>
        </Flex>
      </Flex>
    </Layout>
  );
}

type InputType = {
  score: number;
  goal: number;
}

const Progress = ({ score, goal }: InputType) => {
  const width = Math.min(Math.round((score / goal) * 100), 100) + "%";

  return (
    <Flex flexDir="column" w="100%">
    <Heading fontSize="100px"></Heading>
    <Box h="12px" w="100%" position="relative" background="#d5d5d5" borderRadius="8px" overflow="hidden">
      <Box position="absolute" h="12px" background="#00C6A0" top="0" left="0" w={width} transition="width 1s ease"></Box>
    </Box>
    </Flex>
  );
}

export default Home;

// POSSIBLE FEATURES
// - Login
// - Upload photo with activity
// - Create new groups, challenges etc and invite
// - Differentiated scoring
