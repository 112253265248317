import { Flex, Heading } from "@chakra-ui/react"
import Layout from "./Layout";

const Statistics = () => {
  return (
    <Layout>
      <Flex margin="auto">
        <Heading textAlign="center">En vacker dag kanske det kommer finnas grafer här</Heading>
      </Flex>
    </Layout>
  )
}

export default Statistics;