import { Flex, Text, Spinner } from "@chakra-ui/react";

const Loading = ({ text }: { text: string }) => (
  <Flex w="100%" h="100%" alignItems="center" justifyContent="center" flexDir="column">
    <Spinner size="lg" thickness="6px" color="#003560" />
    <Text fontWeight="bold" fontSize="20px" color="#003560">{text}</Text>
  </Flex>

)

export default Loading;