import { Flex, Heading, HStack, Icon } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import { MdHome, MdInfo, MdBarChart, MdPlaylistAddCheck } from 'react-icons/md';

const Layout = (props: any) => {
  return (
    <Flex flexDir="column" background="#f1f5fb" height="100%" width="100%" boxSizing="border-box" pb="64px" overflow="auto" style={{ WebkitOverflowScrolling: "touch" }}>
      <Flex as="header" flex="0 0 64px" p={{ base: "0 8px 0 16px", md: "0 24px" }} alignItems="center" background="white" boxShadow="0 0 4px 0 rgb(0, 0, 0, 0.15)">
        <Heading as="h1" fontSize={{ base: "20px", md: "26px" }} color="#003560">
          <RouterLink to="/">Reimes Trainers</RouterLink>
        </Heading>
        <HStack spacing="16px" ml="auto" display={{ base: "none", md: "flex" }}>
          <RouterLink to="/">Startsida</RouterLink>
          <RouterLink to="/log">Träningslogg</RouterLink>
          <RouterLink to="/statistics">Statistik</RouterLink>
          <RouterLink to="/about">Om utmaningen</RouterLink>
        </HStack> 

        <HStack spacing="16px" ml="auto" mt={{ base: "8px", md: 0 }} display={{ base: "flex", md: "none" }}>
          <RouterLink to="/"><Icon color="gray.700" w="24px" h="24px" as={MdHome} /></RouterLink>
          <RouterLink to="/log"><Icon color="gray.700" w="24px" h="24px" as={MdPlaylistAddCheck} /></RouterLink>
          <RouterLink to="/statistics"><Icon color="gray.700" w="24px" h="24px"  as={MdBarChart} /></RouterLink>
          <RouterLink to="/about"><Icon color="gray.700" w="24px" h="24px" as={MdInfo} /></RouterLink>
        </HStack>      
      </Flex>
      <Flex as="main" flexDir="column" flex="1" w="100%" p={{ base: "16px", md: "24px" }}>
        {props.children}
      </Flex>
    </Flex>
  )
}

export default Layout;