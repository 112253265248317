import './index.css';
import Home from './Home';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './About';
import Statistics from './Statistics';
import Log from './Log';
import 'moment/locale/sv';
import moment from 'moment';
import theme from './theme';
import { createRoot } from 'react-dom/client';
import { StrictMode } from "react";

moment.locale('sv');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container)

root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/log" element={<Log />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </StrictMode>
);
