import { DeleteIcon } from "@chakra-ui/icons";
import { Flex, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import moment from "moment";
import { useEffect, useState } from "react";
import { Activity } from "./definitions";
import Layout from "./Layout";
import Loading from "./Loading";

const Log = () => {

  const [activities, setActivities] = useState<Activity[]>([]);

  useEffect(() => {
    fetchActivities();
  }, [])

  const fetchActivities = async () => {
    const { data } = await fetch(`${process.env.REACT_APP_API_URL}/activities`).then(res => res.json());
    setActivities(data);
  }

  const deleteActivity = async (activityId: number) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Är du säker på att du vill ta bort aktiviteten?");
    
    if (confirmed) {
      const { success } = await fetch(`${process.env.REACT_APP_API_URL}/activities/${activityId}`, {
        method: "DELETE"
      }).then(res => res.json());

      if (success) {
        // Remove deleted one locally
        setActivities(prev => ([
          ...prev.filter((activity: Activity) => activityId !== activity.id)
        ]));
      }
    }
  }

  if (!activities) {
    return (
      <Layout>
        <Loading text="Laddar aktiviteter" />;
      </Layout>
    )
  }

  return (
    <Layout>
      <Flex flexDir="column" m={{ base: "24px auto", md: "48px auto" }} w="100%">
        <Heading mb="16px">Alla träningspass</Heading>
        <Text mb="24px" fontSize="16px">Här finns fullständig historik över alla träningspass under denna utmaning.</Text>
        <TableContainer background="white">
          <Table variant='striped' overflowX="scroll" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Namn</Th>
                <Th>Träningspass</Th>
                <Th>Längd</Th>
                <Th>Datum</Th>
                <Th>Ta bort</Th>
              </Tr>
            </Thead>
            <Tbody>
              {activities.map((activity, index) => (
                <Tr key={activity.id}>
                  <Td>{index + 1}</Td>
                  <Td>{activity.first_name}</Td>
                  <Td>{activity.title}</Td>
                  <Td>{activity.duration} minuter</Td>
                  <Td>{moment(activity.day).format("YYYY-MM-DD")}</Td>
                  <Td alignSelf="center">
                    <DeleteIcon color="#003158" cursor="pointer" _hover={{ color: "black" }} w="20px" h="20px" flex="0 0 20px" onClick={() => deleteActivity(activity.id)}/>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Layout>
  )
}

export default Log;