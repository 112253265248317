import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Challenge } from "./definitions";
import Layout from "./Layout";
import moment from "moment";
import Loading from "./Loading";

const About = () => {

  const [challenge, setChallenge] = useState<Challenge>();

  useEffect(() => {
    fetchChallenge();
  }, []);

  const fetchChallenge = async () => {
    const { data } = await fetch(`${process.env.REACT_APP_API_URL}/challenges/1`).then(res => res.json());
    setChallenge(data);
  }

  if (!challenge) {
    return (
    <Layout>
      <Loading text="Laddar data" />;
    </Layout>
    );
  }

  return (
    <Layout>
      <VStack maxW="800px" margin={{ base: "24px auto", md: "48px auto" }} spacing="32px" p="24px 32px" borderRadius="12px" bg="white">
          <TextGroup
            title="Vad är detta?"
            text={`Er chef har gått med på att bjuda er på en belöning om ni samlar ihop ${challenge.goal} poäng tillsammans fram till och med ${moment(challenge.ends).format('YYYY-MM-DD')}.`} 
          />
          <TextGroup
            title="Hur räknas poäng?"
            text="Poäng räknas helt enkelt genom att varje fysisk aktivitet genererar 1 poäng per minut som du tränar. Det viktiga är att röra på sig och inte slita hårdast, därför likställs all form av fysisk aktivitet och genererar lika mycket poäng."
          />
          <TextGroup
            title="Hur registrerar jag en aktivitet?"
            text="På startsidan finns ett enkelt formulär där du kan välja vem du är, vilken dag du tränade, hur länge du tränade och vad du tränade. Fyll i formuläret och klicka på skicka! Skulle något bli fel går det tyvärr inte redigera i dagsläget men det går att ta bort och skapa din aktivitet på nytt."
          />
            <TextGroup
            title="Varför gör vi det här?"
            text="Det stärker sammanhållningen inom arbetsgruppen att ha ett gemensamt mål att sträva efter. Träning är dessutom bra för hälsan på alla sätt och vis. Och vem gillar inte en trevlig belöning av chefen?"
          />
          <TextGroup
            title="Har du stött på problem?"
            text="Det här är en första version som är snabbt ihopkastad så det är inte helt omöjligt att något inte fungerar som det är tänkt eller att den innehåller buggar. Skicka gärna ett mail till timlestander@gmail.com om du springer på något."
          />
          <TextGroup
            title="Kör hårt"
            text="Och lycka till!"
          />
      </VStack>
    </Layout>
  )
}

type TextGroupProps = {
  title: string;
  text: string;
}

const TextGroup = ({ title, text }: TextGroupProps) => (
  <Box w="100%">
    <Heading fontSize={{ base: "20px", md: "24px" }} mb="8px">{title}</Heading>
    <Text fontSize={{ base: "16px", md: "18px" }}>{text}</Text>
  </Box>
)

export default About;